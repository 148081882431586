
/* @media (max-width: 992px) {
    .navbar-collapse{
        display: none !important;
    }
} */
@media (max-width: 786px) {
    .hide-996{
        display: none;
    }
    /* .hotel-home-section{
        margin-top: 8em;
      } */
      .modify-search-bg{
        height: 27em;
      }
    .login{
        height: auto;
    }
    .register-steps-list{
        display: block;

    }
    .register-step-item {
        margin-top: 1em;
    }
    .login-form{
        width: 100%;
        margin-top: 2em;
      }
      .login {
        display: flex; /* Use flexbox for centering */
        justify-content: center; /* Center horizontally */
        align-items: center; /* Center vertically */
    }

    .login-form {
        /* Optional: You can set a max width to prevent it from being too wide */
        max-width: 100%; /* Make sure it fits well on small screens */
        width: auto; /* Allow it to auto-resize */
    }
}
@media (max-width: 577px) {
    /* .hotel-home-section{
        margin-top: 10em;
      } */
      .card-slider {
        width: 86%;
        margin: 0 auto;
      }
      .mobile-menu{
        display: block !important;
      }
      .navbar-collapse{
        display: none !important;
      }
      .mobile-drop-down{
        position: absolute;
        top: 2em;
      }
      .d-flex-n{
        justify-content: space-around !important;
        }
      .mobile-text-start{
        align-items: start !important;
        text-align: left !important;
      }
}
@media (max-width: 458px) {
    /* .hotel-home-section{
        margin-top: 12em;
      }
       */
       .slick-prev:before, .slick-next:before {
        font-size: 20px !important;
    }
}
@media (max-width: 373px) {
    /* .hotel-home-section{
        margin-top: 14em;
      } */
      
}