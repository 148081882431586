.login {
  background-image: url(../Assets/Images/login_bg.png);
  background-size: cover; /* Ensures the background image covers the entire area */
  background-repeat: no-repeat; /* Prevents the background from repeating */
  background-attachment: fixed; /* Keeps the background fixed when scrolling */
  height: 110vh; /* Covers the full viewport height */
  width: 100%; /* Covers the full width */
}
.login1 {
  background-image: url(../Assets/Images/login_bg.png);
  background-size: cover; /* Ensures the background image covers the entire area */
  background-repeat: no-repeat; /* Prevents the background from repeating */
  background-attachment: fixed; /* Keeps the background fixed when scrolling */
  height: 100vh; /* Covers the full viewport height */
  width: 100%; /* Covers the full width */
}

  .login-logo{
    width: 96px;
  }
  .login-text{
    font-family: Poppins;
    font-size: 3em;
    font-weight: 600;
    line-height: 60px;
    text-align: left;
    color: #00147A;
  }
  .login-text-1{
   margin-top: 2em;
    font-family: Public Sans;
    font-size: 24px;
    font-weight: 600;
    line-height: 60px;
    text-align: left;

  }
  .login-form{
    width: 80%;
    padding: 1.5em;
    gap: 0px;
    margin: auto;
    border-radius: 30px 30px 30px 30px;
    box-shadow: 0px 4px 50px 0px #00000040;
    background: #FFFFFF;

  }
  .login-form-logo{
    position: relative;
    width: 74px;
    height: 85px;
  }
  .show-text-center{
    justify-content: center;
    display: flex;
    flex-direction: column;
  }
  .signin-text{
    font-family: Public Sans;
    font-size: 18px;
    font-weight: 500;
    color: #8692D0;
    line-height: 24px;
    text-align: left;

  }
  .login-form .label{
    color: #4B465C;
    font-family: Public Sans;
    font-size: 13px;
    font-weight: 400;
    line-height: 15.28px;
    text-align: left;

  }
  .login-form input{
    height: 3em;
    border-radius: 15px 15px 15px 15px;
  }
  .theme-button{
    height: 3em;
    width: 100%;
    color: #fff;
    margin-top: 2em;
    border: none;
   background-color: #46A8A7;
   box-shadow: 0px 1px 2px 0px #0000000D;
    padding: 14px 24px 14px 24px;
    gap: 8px;
    border-radius: 8px ;
  }
  .theme-btn-1{
    color: #fff;
    border: none;
    background-color: #46A8A7;
    box-shadow: 0px 1px 2px 0px #0000000D;
    border-radius: 5px;
  }
  .theme-btn-danger-1{
    color: #fff;
    border: none;
    background-color: red;
    box-shadow: 0px 1px 2px 0px #0000000D;
    border-radius: 5px;
  }
  .theme-text-color{
    color:#46A8A7 ;
  }
  .active-step{
    background-color: #46A8A7 !important;
    color: #fff !important;
  }
  input::placeholder {
    color: rgba(128, 128, 128, 0.445) !important; /* Change the placeholder color */
    opacity: 1; /* Optional: Ensure the placeholder is fully visible */
  }
  .login-footer{
    border-radius: 20px ;
    margin-top: 1em;
    background-color: #051271;
  }
  .login-footer p{
    font-family: Poppins;
    font-size: 14px;
    padding: 8px;
    font-weight: 300;
    text-align: left;
    color:#FFFFFF;

  }
  .register-form{
    max-width: 65em; /* Make sure it fits well on small screens */
    width: auto;
    padding: 1.5em;
    gap: 0px;
    height: 100%;
    margin: auto;
    border-radius: 30px 30px 30px 30px;
    box-shadow: 0px 4px 50px 0px #00000040;
    background: #FFFFFF;
  }
  .register-steps{
    width: 661px;
    height: 42px;
  }
  .register-steps-list {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  
  .register-step-item {
    display: flex;
    align-items: center;
  }
  
  .register-step-icon {
    height: 50px; /* Set the desired height */
    width: 50px;
    display: flex;
    border-radius: 6px;
    background: #F1F0F2;
    justify-content: center;
    align-items: center;
    margin-right: 15px; /* Space between icon and text */
  }
  .register-step-text {
    display: flex;
    flex-direction: column;
  }
  .register-step-text .step-heading{
    font-family: Public Sans;
    font-size: 15px;
    font-weight: 500;
    line-height: 21px;
    text-align: left;
color: #4B465C;
  }
  .register-step-text .step-value{
        font-family: Public Sans;
        font-size: 13px;
        font-weight: 400;
        line-height: 20px;
        text-align: left;
        color: #4b465c73;
        }
        
        .right-angle{
            color: #4b465c73;
        }
        .btn-secondary{
          background-color: rgba(168, 170, 174, 0.16) !important;
          color: rgba(0, 0, 0, 0.5);
          border: none !important;
        }
        .rounded-circle {
          border-radius: 50% !important;
      }
      .nav-user{
        display: -webkit-box;
        display: -ms-flexbox;
        /* display: flex; */
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        gap: 10px;
        background-color: var(--ct-topbar-user-bg);
        min-height: var(--ct-topbar-height);
        -webkit-transition: none;
        transition: none;
      }
      .top-section{
        position: relative;
        height:30em;
        background-size: cover;
       background-position: center;
        background-image: url(../Assets/Images/searchbg.jpeg);
        /* background-color: rgba(70, 168, 167, 1); */

      }
      .top-section .container{
        z-index: 1;
      }
      .top-section::before {
        content: "";
        height:30em;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        background-color: rgba(7, 6, 6, 0.5);
      }
      .hotel-home-section h4{
        color:rgba(5, 18, 113, 1);
          font-weight: 600;
      
      }
      .hotel-home-section{
        margin-top: 1em;
      }
      .card-shadow{
        box-shadow: 0 0 30px 0 rgba(0,0,0,.1);
      }
      /* footer {
        position: relative;
        background-image: url(../Assets/Images/footerbg.png);
        background-size: contain;
        background-repeat: no-repeat;
        background-position: bottom;
        margin-top: 2em;
        background-color: #23a19f91;
        color: rgb(0 0 0);
        font-weight: bold;
    } */
    footer {
      width: 100%;
      bottom: 0;
      left: 0;
      z-index: -1;
      font-size: 12px;
      color: #999;
      background: #000b46;
  }
   footer h3 {
    color: #2aa7a6;
    margin-bottom: 15px;
    font-size: 16px;
}
footer p {
  line-height: 21px;
  margin-bottom: 5px;
}
.rs-modal-wrapper{
  z-index: 9999 !important;
}
 footer a {
  font-size: 12px;
  color: #999;
}
.password-requirements {
  display: flex;
  flex-wrap: wrap;
  padding: 0 2px ;
}
.requirement {
  font-size: 14px;
  flex: 1 0 50%;
  min-width: max-content;
}
.password-requirements p{
  margin-top: 0px;
}
.requirement:before {
  content: '\2022';
  padding-right: 5px;
  font-size: 1.3em;
  position: relative;
  top: .15em;
}
.mt-15 {
  margin-top: 15px;
}
footer .facebook {
  background: #3b5998;
}
footer .sm-icon, footer .icon {
  transform: scale(1.3);
  /* -webkit-transform: scale(1.3); */
  -moz-transform: scale(1.3);
  margin-right: 5px;
}
footer .sm-icon{
  transition: all .2s ease;
  -webkit-transition: all .2s ease;
  -moz-transition: all .2s ease;
  border: solid 1px transparent;
  width: 22px;
  height: 22px;
  display: inline-block;
  text-align: center;
  line-height: 19px;
  font-size: 13px;
  color: #fff;
  transform: scale(.9);
  -webkit-transform: scale(.9);
}
  .sm-sec-pad{
    padding: 20px 0;
}
.linkedin {
  background: #0e76a8;
}
.google-plus {
  background: #c33;
}
.footer-copyrights {
  border-top: solid 1px #30303e;
  padding-bottom: 55px !important;
  padding-top: 15px;
  margin-top: 30px;
}
.page-nav-container {
  position: fixed;
  bottom: 0;
  z-index: 8888;
  width: 100%;
}
.page-nav {
  list-style: none;
  padding: 0;
  margin: 0;
}
.page-nav li{
  display: inline-block;
  margin-left: -3.3px;
}
.page-nav li a {
  transition: all .2s ease;
  -webkit-transition: all .2s ease;
  -moz-transition: all .2s ease;
  border-right: solid 1px #00bf00;
  padding: 15px;
  font-size: 13px;
}
.page-nav li a{
  display: block;
  text-transform: uppercase;
  color: #fff;
}
.green_nav-sub_header {
  text-align: center;
  padding: 15px 10px 14px;
  background: #000b46;
  font-size: 14px;
}
.primary-bg{
  background: #2aa7a6;
}
    .footer-overlay {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgb(255 255 255 / 71%); /* Adjust opacity as needed */
      z-index: 1;
    }
    .centered-modal .rs-modal-body{
      height: 14em;
    }
    .btn-primary{
      background-color: #0054a6 !important;
      border-color: #0054a6 !important;
    }
    .gap-2{
      gap: 1em;
    }
    .meal-table-heading th{
      background-color: #46a8a7;
      color: #ffff;
    }
    footer .container{
      position: relative;
      z-index: 2;
    }
    .margin_60_35 {
      padding-top: 60px;
      padding-bottom: 35px;
  }
  .margin_60 {
    padding-top: 60px;
}
  .follow_us {
    margin-top: 15px;
    animation-delay: 1.1s;
    -webkit-animation-delay: 1.1s;
    -moz-animation-delay: 1.1s;
}
footer ol,footer ul {
  list-style: none;
  margin: 0 0 25px;
  padding: 0;
}

.follow_us ul li {
  display: inline-block;
  font-size: 1.25rem;
}
footer h5 {
  color: #000;
  margin: 25px 0;
  font-size: 1.125rem;
}
 footer ul li a {
  color: #000;
  outline: none;
  text-decoration: none;
}
footer .links li{
  line-height: 2em;
  font-weight: bold;
}
footer .links li a{
  text-decoration: none;
}
.follow_us ul li{
  margin-right: 10px;
}
.nav {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}
.nav-tabs li a{
  background-color: transparent;
  margin: 10px;
  cursor: pointer;
    border:solid 1px #ffffff ;
    border-radius: 3px ;
    color: #fff;
    display: block;
    font-size: 14px;
    margin-right: 1px;
    padding: 7px 25px !important;
    text-decoration: none;
}
.modify-search .nav-tabs li a{
  background-color: transparent;
  margin: 10px;
  cursor: pointer;
    border:solid 1px  #0000001c;
    border-radius: 3px ;
    color: #000;
    display: block;
    font-size: 14px;
    margin-right: 1px;
    padding: 7px 25px !important;
    text-decoration: none;
}
.modify-search .nav-tabs li .activ-search {
  background-color: #46a8a7;
  color: #fff;
}
.hotel-room{
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}
.room_tabs li{
  background-color: transparent;
  margin: 5px;
  /* cursor: pointer; */
  border: solid 1px #46a8a7;
  border-radius: 3px;
  display: block;
  font-size: 13px;
  margin-right: 1px;
  padding: 6px 13px !important;
  text-decoration: none;
}
/* .room_tabs li:hover{
  background-color: #46a8a7;
  color: #fff;
} */
.room_tabs .select_room{
  background-color: #46a8a7;
  color: #fff;
}
.nav-tabs li a:hover{
  background-color: #fff;
  color: #46a8a7;
}
.nav-tabs li .activ-search{
  background-color: #fff ;
  color: #46a8a7 ;
}
.search-content{
  background-color: #fff;
  padding: 1.5em;
  border-radius: 3px;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px;
}
.search-container{
  position: relative;
  bottom: -3em;
}
.adult-btn {
  font-size: 14px;
  border-color: #dee2e6;
}
.rs-picker-error>.rs-picker-input-group, .rs-picker-error>.rs-picker-input-group:hover{
  border-color: #dee2e6 !important;
}
.rs-picker-error>.rs-picker-input-group:focus-within{
  outline: none !important;
}
.css-13cymwt-control{
  border-color: #dee2e6 !important;
}
.hotel-detail-border {
  border-top: 2px solid #46a8a681;
  border-bottom: 2px solid #46a8a681;
}
.select-child {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: .5em;
}
.fff {
  align-items: center;
  border: 1px solid gray;
  border-radius: var(--bui_border_radius_100);
  border-radius: 4px;
  display: flex;
  height: 2.4em;
  overflow: hidden;
}
.adult-modal-btn {
  background-color: initial;
  color: #46a8a7;
  cursor: pointer;
  border: none;
  font-size: 1.5rem;
  outline: none;
  padding: 1rem;
}
.hotel-listing-top-img{
  height: 100%;
  width: 100%;
  object-fit: cover;
}
.hotel-content .view{
  color: rgba(128, 128, 128, 0.747);
}
.hotel-book-btn .btn{
  width: 70%;
}
.hotel-book-btn .theme-btn-1{
  width: 30%;
}
.text-theme-color{
  color:rgba(5, 18, 113, 1);
}
.checkout-summary{
  border-radius: 4px;
  padding: 1em 1.5em;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
}
.checkout-summary .booking-data{
border: solid 1px rgba(129, 126, 126, 0.247);
border-radius: 5px;
}
.mg-top-4{
  margin-top: 4em;
}
.nav-item .dropdown-menu[data-bs-popper] {
  right: 0;
  left: unset;
}
.dropdown-menu li a{
  text-decoration: none;
  cursor: pointer;
}
.nav-link{
  text-decoration: none;
}
/* Full Page Loader */
#preloader {
  position: fixed; /* Ensures the loader stays on top */
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.8); /* White background with transparency */
  z-index: 9999; /* High z-index to cover the entire page */
  display: flex;
  justify-content: center; /* Center horizontally */
  align-items: center; /* Center vertically */
}

/* Circle Loader */
[data-loader="circle-side"] {
  border: 6px solid #f3f3f3; /* Light grey border */
  border-top: 6px solid #3498db; /* Blue color for the animated part */
  border-radius: 50%; /* Makes the loader circular */
  width: 50px;
  height: 50px;
  animation: spin 1s linear infinite; /* Rotates the loader infinitely */
}

/* Keyframes for Spinning Animation */
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.home-card-loader-img{
  height: 14em;
  width: 100%;
  background-color: rgb(128 128 128 / 28%);
  display: flex;
  justify-content: center;
  align-items: center;
}

.ellipsis-text {
  width: 14em;              /* Limit the width to 14em units */
  white-space: nowrap;       /* Prevents the text from wrapping to the next line */
  overflow: hidden;          /* Hides the overflowed text */
  text-overflow: ellipsis;   /* Adds ellipsis (...) when the text overflows */
  display: inline-block;     /* Ensure it behaves as an inline block element */
}
.ellipsis-address{
  width: 100%;
    height: 4em;
    /* white-space: nowrap; */
    overflow: hidden;
    text-overflow: ellipsis;
    display: inline-block;
}
.card-img-top-home{
  height: 14em;
  width: 100%;
  object-fit: cover;
}
.hotel-card{
  cursor: pointer;
}
.hotel-card .score {
  position: absolute;
  right: 15px;
  top: 15px;
  z-index: 1;
}
.hotel-card .custom_tag {
  position: absolute;
  left: 15px;
  top: 15px;
  z-index: 1;
}
.hotel-card .custom_tag strong {
  border-radius: 5px 5px 5px 0;
  line-height: 1;
  padding: 10px;
  background-color: #0054a6;
    color: #fff;
    display: inline-block;
}
.hotel-card  .score strong {
  border-radius: 5px 5px 5px 0;
  line-height: 1;
  padding: 10px;
  background-color: #0054a6;
    color: #fff;
    display: inline-block;
}

.rs-picker-has-value .rs-btn .rs-picker-toggle-value, .rs-picker-has-value .rs-picker-toggle .rs-picker-toggle-value {
  color: #000 !important;
}
.d-upload .rs-uploader-trigger-btn{
width: 100%;
}
.Billing .rs-uploader-trigger-btn{
  width: 100%;
  }
.d-upload .rs-uploader-file-items{
  max-height: 10em;
  overflow-x: hidden !important;
  overflow-y: scroll !important;
}
.nav.nav-divider .nav-item {
  display: inline-block;
  color: #747579;
  vertical-align: middle;
}
.nav.nav-divider .nav-item+.nav-item:before {
  color: inherit;
  content: "•";
  opacity: .8;
  padding-left: .65rem;
  padding-right: .75rem;
}
.room-request {
  background: #438f1d3d;
  border-radius: 5px;
  color: red;
  padding: .5em;
}
.sticky-element {
  position: -webkit-sticky;
  position: sticky;
  top: 5em;
}
.pagination{
  justify-content: center !important;
}
.pagination .page-item a{ 
 text-decoration: none;
}
/* Loader.css */
.Booking-loader-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.Booking-loader-content {
  text-align: center;
    background: #fff;
    padding: 3em;
    border-radius: 5px;
}

.Booking-spinner {
  border: 16px solid #f3f3f3;
  border-top: 16px solid #3498db;
  border-radius: 50%;
  width: 120px;
  height: 120px;
  animation: spin 2s linear infinite;
  margin: auto;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
@media print{
  .print-div-hide{
    display: none !important;
  }
  .invoice-address{
    font-size: 13px;
  }
}
.hstack, .vstack {
  align-self: stretch;
  display: flex;
}
.hstack {
  align-items: center;
  flex-direction: row;
}
.bg-mode {
  background: #fff !important;
  background: var(--bs-mode) !important;
}
.bg-mode {
  --bs-bg-opacity: 1;
  background-color: rgba(255, 255, 255, var(--bs-bg-opacity)) !important;
  background-color: rgba(var(--bs-mode-rgb), var(--bs-bg-opacity)) !important;
}
.fw-normal {
  font-weight: 500 !important;
}
.shadow {
  box-shadow: 0 0 40px #1d3a531a !important;
  box-shadow: var(--bs-box-shadow) !important;
}
.h-20px {
  height: 20px !important;
}
.about-us-img img{
  height: auto;
  max-width: 100%;
}
.bg-facebook {
  background-color: #5d82d1;
  border: none;
  color: #fff;
}
.bg-instagram {
  background: #c22b72;
  border: none;
  color: #fff;
}
.bg-linkedin {
  background-color: #238cc8;
  border: none;
  color: #fff;
}
.icon-lg {
  font-size: 1.2rem;
  height: 3.5rem;
  line-height: 3.5rem;
  text-align: center;
  width: 3.5rem;
}
.rounded-circle {
  border-radius: 50% !important;
}
.text-orange {
  --bs-text-opacity: 1;
  color: orange !important;
}
.bg-opacity-10 {
  --bs-bg-opacity: 0.1;
}
.btn-primary-soft:hover {
  background-color: #46a8a7;
  border-color: #46a8a7;
  color: #fff;
}
.btn-primary-soft {
  background-color: #5143d91a;
  color: #46a8a7;
}
.bg-light {
  --bs-bg-opacity: 1;
  background-color: rgba(245, 245, 246, var(--bs-bg-opacity)) !important;
  background-color: rgba(var(--bs-light-rgb), var(--bs-bg-opacity)) !important;
}
.border-none{
  border: none !important;
}
.nav-pills-primary-soft .nav-link.active, .nav-pills-primary-soft .nav-link:hover {
  background-color: #5143d91a;
  background-color: rgb(70 168 167 / 17%);
  color: #fff;
}
.nav-pills-primary-soft .nav-link {
  border-radius: .5rem;
  color: #fff;
  font-weight: 500;
  padding: .5rem .8rem !important;
}
.border {
  border: 1px solid #dfdfe3 !important;
  border: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}
.icon-xl {
  font-size: 1.5rem;
  height: 3rem;
  line-height: 3rem;
  text-align: center;
  width: 3rem;
}
.nav.nav-bottom-line .nav-link.active {
  background: #0000;
  border-bottom: 3px solid #46a8a7;
  color: #46a8a7;
}
.nav.nav-bottom-line .nav-link {
  background: #0000;
  border: #0000;
  color: #fff;
  border-bottom: 3px solid #0000;
  font-weight: 600;
  padding: .6rem .75rem;
  white-space: nowrap;
}
.make_payment .rs-uploader-picture .rs-uploader-trigger-btn {
  height: 16em !important;
  width: 16em !important;
}
.make_payment .rs-uploader-picture .rs-uploader-trigger-btn svg {
  height: 3em !important;
  width: 3em !important;
}
.make_payment .rs-uploader-picture .rs-uploader-file-item-preview {
  height: 16em !important;
}
.make_payment .rs-uploader-picture .rs-uploader-file-item {
  height: 16em !important;
  width: 16em !important;
} 

/* Full height for the sidebar */
#offcanvasSidebar {
 
  height: 100vh; /* Full height of the viewport */
  position: fixed; /* Fix to the side of the screen */
  top: 0;
  left: 0;
  width: 22%;
  z-index: 1050;
  overflow-y: auto; /* Allow sidebar content to scroll if needed */
}
#offcanvasSidebar .offcanvas-body .card{
  background-color: #1a2b48 !important; /* Blue background */
  color: #ffffff; /* White text color */
}
#offcanvasSidebar .offcanvas-body {
  height: 100%;
}
/* Adjust the main content area */
.content-area {
  margin-left: 25%; /* Adjust based on sidebar width */
  padding: 1rem;
  overflow-y: auto; /* Independent scrolling */
  height: 100vh; /* Full height of the viewport */
}


@media (max-width: 992px) {
  #offcanvasSidebar {
    position: relative;
    height: auto;
    margin-left: 0;
  }
  .content-area {
    margin-left: 0;
    height: auto;
  }
}
@media (max-width: 556px) {
  .home-top-text h2{
    font-size: 2rem;
  }
  .home-top-text h3{
    font-size: 1.2rem;
  }
}



.dash-index-top{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.col-padding-left{
  padding-left: 0 !important;
}
.avatar-xl {
  height: 5.125rem;
  width: 5.125rem;
}
.avatar {
  height: 3rem;
  width: 3rem;
  position: relative;
  display: inline-block !important;
}
.avatar-img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}
.rounded-circle {
  border-radius: 50% !important;
}
.bg-orange{
  background-color: #ffa5002e;
}

/* Hotel Filter Css */

#filters_col {
  background-color: #fff;
  padding: 20px 20px 15px;
  margin-bottom: 25px;
  border: 1px solid #ededed;
}
a#filters_col_bt {
  display: block;
  color: #333;
  text-decoration: none;
  position: relative;
  font-size: 1rem;
  font-weight: 600;
}
.filter_type h6 {
  border-top: 1px solid #ededed;
  margin: 15px 0;
  padding: 15px 0 0;
  font-size: .8125rem;
}
.filter_type ul {
  list-style: none;
  padding: 0;
  margin: 0 0 15px;
}
.filter_type ul li{
  margin-bottom: 5px;
}
#filters_col label {
  color: #333;
  font-weight: 400;
  font-size: .8125rem;
}
.container_check {
  display: block;
  position: relative;
  padding-left: 30px;
  line-height: 1.8;
  margin-bottom: 5px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.container_check input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}
.container_check .checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
  border: 1px solid #d2d8dd;
  background-color: #fff;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  border-radius: 3px;
  transition: .3s ease-in-out;
}
.container_check .checkmark:after {
  content: "";
  position: absolute;
  display: none;
  left: 7px;
  top: 3px;
  width: 5px;
  height: 10px;
  border: solid #fff;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
.container_check input:checked~.checkmark:after, .content section.content-current, .hero_single.cat_hover .cat_nav_hover ul li a, .leaflet-control-layers label, .leaflet-marker-icon, .leaflet-marker-shadow, .no-csspointerevents .nice-select.open .list, .no-js .flexslider .slides>li:first-child, .pictures figure a, .pictures_grid figure a, html[xmlns] .flexslider .slides {
  display: block;
}
.container_check input:checked~.checkmark {
  background-color: #0054a6;
  border: 1px solid transparent;
}
.cursor-pointer{
  cursor: pointer;
}
.filters_listing {
  padding: 10px 0 5px;
  background: #fff;
  /* border-bottom: 1px solid #ededed; */
  /* z-index: 999999 !important; */
  position: relative;
}
#reccomended .item, .filters_listing ul {
  margin: 0 15px;
}

.filters_listing .layout_view {
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  border-radius: 3px;
  border: 1px solid rgba(0, 0, 0, .08);
  padding: 4px 8px 3px;
}
.filters_listing ul li:nth-child(3), .nice-select.right, ul.cart_details li span {
  float: right;
}
.filters_listing .btn_map {
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  border-radius: 3px;
  padding: 8px 12px;
  line-height: 1;
  display: inline-block;
  border: 1px solid rgba(0, 0, 0, .08);
  font-weight: 600;
  font-size: .75rem;
  color: rgba(0, 0, 0, .5);
}

.filters_listing ol, ul {
  list-style: none;
  margin: 0 0 25px;
  padding: 0;
}

.layout_view p{
  color: rgba(0, 0, 0, .5);
}
.clearfix .active{
  color: #000 !important;
}
.collapse .map {
  width: 100%;
  height: 500px;
}


.box_list figure {
  margin-bottom: 0;
  overflow: hidden;
  position: relative;
  min-height: 19em;
}
.box_list figure small {
  position: absolute;
  right: 5em;
  top: 20px;
  color: #ccc;
  font-weight: 600;
  border-radius: 3px;
  /* z-index: 99; */
}
.box_list figure .custom-tag1 {
  right: 2em !important;
 
}
.mobile-menu{
  display: none !important;
}
.mobile-drop-menu{
  position: absolute !important;
  top: 2em !important;
}
.box_list figure small {
  border-radius: 5px 5px 5px 0;
    line-height: 1;
    padding: 10px;
    background-color: #0054a6;
    color: #fff;
    display: inline-block;
}
.box_list figure img {
 object-fit: cover;
  backface-visibility: hidden;
  transition: .3s ease-in-out;
    height: 19em;
    width: 100%;
}
.box_list .wrapper {
  padding: 30px 30px 20px;
  /* min-height: 245px; */
  position: relative;
}
.cat_star svg{
  color: #ffc107 !important;
  margin-right: 2px;
}
.box_list .wrapper h3 {
  font-size: 1.25rem;
  margin-top: 0;
}
.box_list .wrapper p {
  /* margin-bottom: 30px; */
  color: #999;
}
.box_list .wrapper .price {
  display: inline-block;
  font-weight: 500;
  color: #999;
}
.box_list .wrapper .price strong {
  color: #32a067;
}
.box_list .list-ul {
  border-top: 1px solid #ededed;
}
.box_list .list-ul {
  padding: 15px 15px 15px 30px;
  margin-bottom: 0;
}
.score strong {
  background-color: #0054a6;
  color: #fff;
  display: inline-block;
  border-radius: 5px 5px 5px 0;
    padding: 10px;
}
.box_list .list-ul li:last-child {
  margin-right: 0;
  float: right;
}
.box_list {
  margin: 0 15px 18px;
  -webkit-box-shadow: 0 0 30px 0 rgba(0, 0, 0, .1);
  -moz-box-shadow: 0 0 30px 0 rgba(0, 0, 0, .1);
  box-shadow: 0 0 30px 0 rgba(0, 0, 0, .1);
}
.box_list .wish_bt {
  position: absolute;
  right: 15px;
  top: 15px;
  text-decoration: none;
  z-index: 1;
  background-color: rgba(0, 0, 0, .1);
  padding: 9px 10px;
  display: inline-block;
  color: #555;
  border-radius: 3px;
}
.map-img{
  width: 13em;
  height: 10em;
  object-fit: cover;
}

.cs-container {
  max-width: 1000px;
  padding: 30px 15px;
  margin-left: auto;
  margin-right: auto;
  }
  .cs-invoice.cs-style1 {
    background: #fff;
    border-radius: 10px;
    padding: 40px;
    }
    .invoice-text-theme h4{
      color: #2b8ead;
    }
    .invoice-text-theme p{
      color: rgba(141, 137, 137, 0.411);
    }
    .voucher-bg{
      background-color: #2b8ead;
      color: #ffff;
    }
    .voucher-bg ul li {
      margin-top: .5em;
      margin-bottom: .5em;
    }
    .text-success-voucher{
      color: #25e78f;
    }
    .text-light-color{
      color: rgba(141, 137, 137, 0.411);
    }
    .mt-30{
      margin-top: 3em;
    }
    .vouvher-terms h5{
      background: #7fedff5e;
      color: #59bfd1;
    }
    .vouvher-terms{
      border: 1px solid #b0e7f1;
      border-radius: 4px;
    }
    .hotel-invoice_guest{
      border-radius: 4px;
      background-color: rgba(148, 145, 145, 0.219);
      padding: .6em;
    }
    .modify-overlay{
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.5); 
      z-index: 1;
    }
    .modify-search-bg{
      position: relative;
    background-image:url(../Assets/Images/bg3.jpeg);
    background-size:cover;
    background-position: center;
    background-repeat: no-repeat;
    height: 25em;
    }
    .modify-search-bg::before {
      content: "";
      height: 25em;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      background-color: rgba(7, 6, 6, 0.5);
  }
    .actual-search-modify{
      position: relative;
      z-index: 2;
    }
    .modify-search .nav-tabs li a {
      background-color: #fff ;
      color: #46a8a7 ;
    }
    small{
      font-size: 12px !important;
    }
    .forget-password-link small{
      text-decoration: none;
      cursor: pointer;
    }
    .forget-password-link {
      text-decoration: none;
      cursor: pointer;
    }

    .authentication-bg .account-pages {
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      min-height: 100%;
      /* background-image: url(../Assets/Images/login_bg.png); */
     
  }
  .authentication-bg{
    min-height: 100vh;
    background-size: cover;
    background-image: url(../Assets/Images/login_bg.png);
  }
  .otp-field div {
    margin-bottom: 2em;
    margin-top: 2em;
    justify-content: space-around;
  }
  .input-group-text {
    padding: 10px !important;
    height: -webkit-fill-available !important;
  }
  .profile_card {
    background: #46a8a7;
    box-shadow: 0px 0px 35px 0px rgba(154, 161, 171, 0.15);
    margin-bottom: 1.5rem;
    border: none !important;
}
.profile_card .avatar-lg {
  height: 6rem;
  width: 6rem;
}
.profile_card .rounded-circle {
  border-radius: 50% !important;
}
.profile_card .img-thumbnail {
  padding: .25rem;
  background-color: #fafbfe;
  border: 1px solid #dee2e6;
  max-width: 100%;
  height: auto;
}
.hr{
  border-bottom: 1px solid #80808036;
}
.slick-prev:before, .slick-next:before {
  font-family: 'slick';
  font-size: 26px !important;
  line-height: 1;
  opacity: .75;
  color: #000 !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.hotel-card figure small {
  position: absolute;
  right: 20px;
  top: 20px;
  color: #ccc;
  font-weight: 600;
  border-radius: 3px;
}
.hotel-card figure small {
  border-radius: 5px 5px 5px 0;
  line-height: 1;
  padding: 10px;
  background-color: #0054a6;
  color: #fff;
  display: inline-block;
}
.availability_search .adult-modal-btn{
font-size: 1.3em !important;
}
.availability_search .select-child select{
font-size: .8rem;
}

.cancel-modal-header{
  background-color: #46A8A7 ;
  padding: .5em;
  border-radius: 3px;
}
.cancel-modal-header h4{
color: #ffff;
}
.rs-modal-body {
  max-height: auto !important;
}
.rs-modal-header .rs-modal-header-close {
  top: 30px !important;
  right: 25px !important;
  color: #fff !important;
}
.hotel-card{
  height:100%
}
.text-decoration-none{
  text-decoration: none !important;
}
.card-slider {
  width: 98%;
  margin: 0 auto;
}
.slick-prev:before, .slick-next:before {
  
  color: #0054a6 !important;
 
}
.rs-picker-daterange-header {
  border-bottom: 1px solid #011079 !important;

}
.rs-picker-popup.rs-picker-popup-daterange .rs-calendar:first-child {
  border-right: 1px solid #011079 !important;
}
.rs-picker-toolbar {
  border-top: 1px solid #011079 !important;
}
.centered-modal {
  top: 35% !important;
}
.room_tabs1 li {
  background-color: transparent;
  margin: 5px;
  /* cursor: pointer; */
  border: solid 1px #46a8a7;
  border-radius: 3px;
  display: block;
  font-size: 13px;
  margin-right: 1px;
  padding: 3px 4px !important;
  text-decoration: none;
}
.add-vredit-group span{
  line-height: 24px !important;
  margin-top: auto !important;
  padding: 6px !important;
}
.payment_options li{
  cursor: pointer;
}
.ledger-top-tr th,.ledger-top-tr td{
  background-color: #46a8a7;
  color: #ffff;
}
.home-about-section p{
  text-align: justify;
  font-size: 17px;
  font-weight: 400;
}
.home-about-section h2{
  font-family: serif;
}

.highlighted-row {
  background-color: #46a8a7; /* Light blue background */
  color: #ffff; /* Adjust text color if needed */
}
.highlighted-row:hover {
  color: #000; /* Adjust text color if needed */
}